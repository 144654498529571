.navbar__custom {
	background-color: #f8f8f8;
	// position: sticky;
	transition: all 0.3s;
	z-index: 1019;
	border-bottom: 1px solid rgb(226, 225, 225);
}

.navbar__item {
	margin-left: 10px;
	padding: 5px 10px;
	color: gray !important;
	text-decoration: none !important;
	border-radius: 5px;
	background-color: transparent;
	transition: all 0.2s;

	&:hover {
		background-color: lightgray;
	}

	&.active {
		color: white !important;
		// background-color: rgb(70, 69, 69);
		background-color: #dc3545;
	}
}
