@import "./Footer.scss";
@import "./Navbar.scss";
@import "./Map.scss";

.app {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
}

.app__middle {
	flex: 1;
}

.sidebar__link {
	color: gray !important;
	display: block;
	text-decoration: none !important;
	font-weight: 400;
	transition: all 0.2s;

	&:hover {
		color: black !important;
	}

	&.active {
		color: black !important;
		font-weight: 600;
	}
}

.nav-icon {
	width: 20px;
	height: 20px;
}

.category {
	width: 100%;
	height: 500px;
	background-position: center;
	position: relative;
	background-size: cover;
	transition: background-size 0.5s;
	background-color: rgb(247, 244, 244);
	transition: all 0.3s;

	&:hover {
		transform: scale(1.08);
	}
}

.category__name {
	padding: 10px;
	position: absolute;
	bottom: 20px;
	left: 20px;
	background-color: rgba(0, 0, 0, 0.75);
	width: fit-content;
	max-width: 250px;
	color: white;
}

.rtl {
	.category__name {
		padding: 10px;
		position: absolute;
		bottom: 20px;
		right: 20px;
		background-color: rgba(0, 0, 0, 0.75);
		width: fit-content;
		max-width: 250px;
		color: white;
	}

	.landingPage__colorsText {
		background-color: rgba(0, 0, 0, 0.6);
		color: white;
		position: absolute;
		bottom: 20px;
		right: 10vw;
		width: fit-content;
		max-width: 80vw;
		font-weight: 300;

		h3 {
			font-weight: 300;
		}
	}
}

.custom__card {
	box-shadow: 0 0rem 0rem rgba(0, 0, 0, 0);
	transition: all 0.2s;
	// border: 1px solid lightgray;
	border-radius: 10px;

	&:hover {
		transform: translateY(-10px);
		box-shadow: 0 0.5rem 1.5rem rgba(0, 0, 0, 0.3);
	}
}

.landingPage__colors {
	position: relative;
	height: 512px;
	margin-bottom: 200px;
}

.landingPage__colorsText {
	background-color: rgba(0, 0, 0, 0.6);
	color: white;
	position: absolute;
	bottom: 20px;
	left: 10vw;
	font-size: 20px;
	padding: 20px;
	max-width: 80vw;
	font-weight: 300;

	h3 {
		font-weight: 300;
	}
}

.swiper-slide-prev {
	opacity: 0.7;
}

.swiper-slide-next {
	opacity: 0.7;
}

.swiper-button-next::after {
	display: none;
}

.swiper-button-next {
	height: 48px;
	width: 48px;
	border-radius: 50%;
	background: #dc3545;
	display: flex;
	color: white;
	font-size: 25px;
	right: 10vw;
}

.swiper-button-prev {
	height: 48px;
	width: 48px;
	border-radius: 50%;
	background: #dc3545;
	display: flex;
	color: white;
	font-size: 25px;
	left: 10vw;
}

.swiper-button-prev::after {
	display: none;
}

.swiper-pagination-bullet-active-main {
	background-color: #dc3545;
}

@media (max-width: 768px) {
	.swiper-button-prev,
	.swiper-button-next {
		display: none;
	}
}

.custom__navbar {
	background-color: white;
	position: sticky;
	top: 71px;
}

.navLogo {
	height: 45px;
	object-fit: contain;
	transition: all 0.3s;

	&.navLogo__small {
		height: 25px;
	}
}

@media (max-width: 992px) {
	.custom__navbar {
		border-bottom: 1px solid rgb(228, 228, 228);
	}
}
