.footer {
	background-color: #f7f8fb;
	padding: 30px 15px;
}

.footer__logo {
	width: 200px;
	object-fit: contain;
}

.footer__whatsapp {
	background-color: #00e676;
	color: white;
	width: fit-content;
	height: fit-content;
	padding: 10px 20px;
	border-radius: 5px;

	svg {
		font-size: 25px;
	}
}
